import React from 'react';

function Footer() {
    return (
        <footer className="footer-wrapper">
            <div className="vp-footer">
                Sunthar.com is owned by <a href="https://atlantdesigns.com" target="_blank" rel="noopener noreferrer">AtlantDesigns Inc.</a>
            </div>
            <div className="vp-copyright">
                Copyright © 2024 Sunthar's Super Sector
            </div>
        </footer>
    );
}

export default Footer;