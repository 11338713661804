import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import NavbarComponent from './components/Navbar';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import Affiliate from './pages/Affiliate';
import Success from './pages/Success';
import CartProvider from './CartContext';

function App() {

  return (
    <CartProvider>
      <div className="theme-container no-sidebar">
        <NavbarComponent></NavbarComponent>
        <BrowserRouter>
          <Routes>
            <Route index element={<Store />} />
            <Route path="/:storeName" element={<Store />} />
            <Route path="success" element={<Success />} />
            <Route path="cancel" element={<Cancel />} />
            <Route path="affiliate" element={<Affiliate />} /> {/* New route for affiliate */}
          </Routes>
        </BrowserRouter>
      </div>
    </CartProvider>
  );
}

export default App;
