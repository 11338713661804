import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import { CartContext } from '../CartContext';
import { useContext } from 'react';
import ToastComponent from '../components/Toast';

function ProductCard(props) { // props.product is the product we are selling
    const product = props.product;
    const onImageClick = props.onImageClick; 
    const cart = useContext(CartContext);
    const productCartQuantity = cart.getCartProductQuantity(product.id);
    const toast = useContext(ToastComponent);
    //console.log(cart.items);
    return (
        <Card>
            <Card.Body>
                {product.isFreeShipping && <Card.Stopper>Free shipping</Card.Stopper>}
                <img
                  className='product-image'
                  src={product.images[0]}
                  onClick={() => onImageClick(product)} 
                  alt={product.name}
                />
                <div style={{ height: '70px', padding: '10px' }}>
                  <small className='product-name'>{product.name}</small>
                </div>
                <div>
                  {product.type === 'affiliate' ? (
                    <>
                      <Card.Text><strong>{cart.formatPrice(product.default_price, product.currency)}</strong></Card.Text>
                    </>
                  ) : (
                    <>
                      <Card.Text><strong>{cart.formatPrice(product.prices[0].unit_amount, product.prices[0].currency)}</strong></Card.Text>
                      {product.inventory <= 0 ? (
                        <Card.Text style={{ color: '#ff4554' }}>Out of Stock</Card.Text>
                      ) : (
                        <Card.Text style={{ color: '#b8e986' }}>{product.inventory} in stock</Card.Text>
                      )}
                    </>
                  )}
                  {product.type === 'affiliate' ? (
                    <a href={product.affiliate_link} target="_blank" className='sunthar-add-button' style={{width: '100%'}}>Shop Now!</a>
                  ) : (
                    product.inventory > 0 ? (
                      <div>
                        {productCartQuantity > 0 ?
                          <>
                            <Form as={Row}>
                              <Col xs='3' style={{ paddingRight: '0px' }}>
                                <a onClick={() => cart.removeOneFromCart(product.id)} className='sunthar-remove-button'>&mdash;</a>
                              </Col>
                              <Col xs='9'>
                                <a onClick={() => cart.addOneToCart(product.id, product.inventory)} className='sunthar-add-button' style={{width: '100%'}}>Add To Cart</a>
                              </Col>
                            </Form>
                          </>
                          :
                          <a onClick={() => cart.addOneToCart(product.id, product.inventory)} className='sunthar-add-button' style={{width: '100%'}}>Add To Cart</a>
                        }
                      </div>
                    ) : (
                      <Button variant='secondary' disabled className='sunthar-add-button' style={{width: '100%'}}>Out of Stock</Button>
                    )
                  )}
                </div>
            </Card.Body>
        </Card>
    )
}

export default ProductCard;

/*
{
   'id':"prod_QAQXdWXMhdR5y8",
   "object":"product",
   "active":true,
   "attributes":[
      
   ],
   "created":1716586163,
   "default_price":"price_1PK5g0Hp2Iufo3VG0o1vxkzT",
   "description":null,
   "features":[
      
   ],
   "images":[
      
   ],
   "livemode":false,
   "marketing_features":[
      
   ],
   "metadata":{
      
   },
   "name":"MD2 to SCART female breakout cable",
   "package_dimensions":null,
   "shippable":null,
   "statement_descriptor":null,
   "tax_code":null,
   "type":"service",
   "unit_label":null,
   "updated":1716586197,
   "url":null,
   "prices":[
      {
         "id":"price_1PK5g0Hp2Iufo3VG0o1vxkzT",
         "object":"price",
         "active":true,
         "billing_scheme":"per_unit",
         "created":1716586164,
         "currency":"usd",
         "custom_unit_amount":null,
         "livemode":false,
         "lookup_key":null,
         "metadata":{
            
         },
         "nickname":null,
         "product":"prod_QAQXdWXMhdR5y8",
         "recurring":null,
         "tax_behavior":"unspecified",
         "tiers_mode":null,
         "transform_quantity":null,
         "type":"one_time",
         "unit_amount":1500,
         "unit_amount_decimal":"1500"
      }
   ],
   "price":15
}
*/