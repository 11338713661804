import {Modal} from 'react-bootstrap';
import { useState, useContext } from 'react';
import { CartContext } from "../CartContext";
import CartProduct from './CartProduct';

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function NavbarComponent() {
    const cart = useContext(CartContext);

    const shippingRate = cart.getShippingRate();

    const [show, setModalShow] = useState(false);
    const modalClose = () => setModalShow(false);
    const modalShow = () => setModalShow(true);

    const checkout = async () => {

        console.log ('Items: ' + JSON.stringify(cart));
        console.log ('Shipping: ' + shippingRate);
        
        await fetch(STORE_BACKEND_URL+'/stripe/checkout', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({items: cart.items, shipping:shippingRate})
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if(response.url) {
                window.location.assign(response.url); // Forwarding user to Stripe
            }
        });
    }

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    return (
        <>
          <header id="navbar" className="vp-navbar">
          <div className="vp-navbar-start">
            <a href="https://sector.sunthar.com/">
            <img
                className="vp-nav-logo"
                src="/logo.png"
                alt="Sunthar's Super Store"
            />
            </a>
            <a href="https://store.sunthar.com/">
              <span className="vp-site-name">Sunthar's Super Store</span>
            </a>
          </div>
          <div className="vp-navbar-end">
            <a 
              className={`sunthar-checkout-button ${productsCount > 0 ? 'color-breathing' : ''}`} 
              onClick={modalShow}
            >
              <i className="bi bi-cart"></i> Shopping Cart ({productsCount} Items)
            </a>
          </div>
          </header>

          <Modal show={show} onHide={modalClose}>
            <Modal.Header style={{padding: '5px'}}>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={modalClose}></button>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="row">
                    {productsCount > 0 ?
                        <>
                            <div className="col-md-8 cart">
                                <div className="row">
                                    <div className="title">
                                        <div className="row">
                                            <div className="col-8">
                                                <h4><b>Shopping Cart</b></h4>
                                            </div>
                                            <div className="col-4 align-self-center text-end text-muted">
                                                {productsCount}  items
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {cart.items.map( (currentProduct, idx) => (
                                    <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                                ))}
                            </div>
                            <div className="col-md-4 summary">
                                <div>
                                    <h5>
                                    <b>Summary</b>
                                    </h5>
                                </div>
                                <hr />
                                <div className="row" style={{ paddingBottom: "10px" }}>
                                    <div className="col">
                                    ITEMS ({productsCount})
                                    </div>
                                    <div className="col text-end">$ {cart.getSubTotalCost().toFixed(2)}</div>
                                </div>
                                <div className="row" style={{ paddingBottom: "10px" }}>
                                    <div className="col">
                                    SHIPPING
                                    </div>
                                    <div className="col text-end">$ { cart.getShippingCost().toFixed(2) }</div>
                                </div>
                                <div
                                    className="row"
                                    style={{ borderTop: "1px solid rgba(0,0,0,.1)", padding: "10px 0px", marginTop: "30px" }}
                                >
                                    <div className="col-6">TOTAL</div>
                                    <div className="col-6 text-end">$ {cart.getTotalCost().toFixed(2)}</div>
                                </div>
                                <div
                                    className="row"
                                    style={{ padding: "10px 0px", marginTop: "30px" }}
                                >
                                    <a className="sunthar-checkout-button" onClick={checkout}>PURCHASE</a>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div onClick={modalClose} className="col-md-12 cart text-center"  style={{borderRadius:"1rem"}}>
                                <h4>There are no items in your cart!</h4>
                            </div>
                        </>
                    }
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="back-to-shop">
                          <span onClick={modalClose} className="text-muted">← Back to shop</span>
                        </div>
                      </div>
                    </div>
                </>
            </Modal.Body>
          </Modal>
        </>
    )
}

export default NavbarComponent;