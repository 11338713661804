import React, { useContext, useState } from 'react';
import { Row, Col, Container, Modal, Button } from 'react-bootstrap';
import { useAllProducts } from '../productsStore';
import { CartContext } from '../CartContext';
import ProductCard from '../components/ProductCard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Footer from '../components/Footer';

function Affiliate() {
    const productsArray = useAllProducts();
    const affiliateProducts = productsArray.filter(product => product.type === 'affiliate');
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const cart = useContext(CartContext);

    const handleImageClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    return (
        <>
          <Container>
            <ToastContainer />
            <div className="store-page-wrapper">
              <Row xs={1} md={3} lg={4} className="g-4">
                {affiliateProducts.map((product, idx) => (
                  <Col align="center" key={idx}>
                    <ProductCard product={product} onImageClick={handleImageClick} />
                  </Col>
                ))}
              </Row>

              {/* Product Details Modal */}
              <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header>
                  <Modal.Title>{selectedProduct?.name}</Modal.Title>
                  <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                  {selectedProduct && (
                    <>
                      <img
                        src={selectedProduct.images[0]}
                        alt={selectedProduct.name}
                        style={{ width: '100%', marginBottom: '15px' }}
                      />
                      <p><strong>Description:</strong> {selectedProduct.description}</p>
                      <p><strong>Price:</strong> {cart.formatPrice(selectedProduct.default_price, selectedProduct.currency)}</p>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                {selectedProduct && (
                  <>
                    <a onClick={handleClose} className="sunthar-close-button">Close</a>
                    <a href={selectedProduct.affiliate_link} target="_blank" className='sunthar-add-button'>Shop Now!</a>
                  </>
                )}
                </Modal.Footer>
              </Modal>

              <div className="hint-container warning">
                <p>Commissions are earned when you purchase from this page. I'm only show casing products that I have personally tried. If you have any questions, please feel free to reach out.</p>
                <p>support@sunthar.com</p>
              </div>
            </div>
          </Container>
          <Footer />
        </>
    );
}

export default Affiliate;