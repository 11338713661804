import { Toast } from 'react-bootstrap';
import { useState, useContext } from 'react';

function ToastComponent() {

  const [showToast, setToastShow] = useState(false);
  const toastShow = () => setToastShow(true);

    return (
      <Toast onClose={() => setToastShow(false)} show={showToast} delay={3000} autohide> {/*delay={3000} autohide*/}
        <Toast.Header>
          <img
          src="holder.js/20x20?text=%20"
          className="rounded me-2"
          alt=""
          />
          <strong className="me-auto">Message</strong>
          <small>{/*11 mins ago*/}</small>
        </Toast.Header>
        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
      </Toast>
    )
}

export default ToastComponent;