import { createContext, useState } from "react";
import { getProductData, useShipping } from "./productsStore";
import {ToastContainer, toast} from 'react-toastify';

export const CartContext = createContext({
    items: [],
    getCartProductQuantity: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getSubTotalCost: () => {},
    getShippingCost: () => {},
    setShippingRate: () => {},
    getShippingRate: () => {},
    getTotalCost: () => {},
    setToast: () => {},
    formatPrice: () => {}
});

export function CartProvider({children}) {
    const [cartProducts, setCartProducts] = useState([]);

    const shippingTiers = useShipping();
    
    // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]

    function setToast(id) {
        console.log('Toast Received: ' + getProductData(id).name)
        return id;
    }

    function getCartProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;
        
        if (quantity === undefined) {
            return 0;  
        }

        return quantity;
    }

    function formatPrice (price, currency) {
      const currencyString = currency.toUpperCase();
      switch (currencyString) {
        case 'EUR':
          return 'EUR'+(price/100).toFixed(2).replace('.', ',');
        case 'GBP':
          return '£'+(price/100).toFixed(2);
        default:
          return '$'+(price/100).toFixed(2);
      }
    };

    function addOneToCart(id, inventory) {
        const quantity = getCartProductQuantity(id);
        const productData = getProductData(id);

        console.log ('Adding one to Cart. Product Data: ' + JSON.stringify(productData));

        if (quantity < inventory) {

          if (quantity === 0) { // product is not in cart
              setCartProducts(
                  [
                      ...cartProducts,
                      {
                          id: id,
                          price_id: productData.prices[0].id,
                          quantity: 1
                      }
                  ]
              )
          } else { // product is in cart
              // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]    add to product id of 2
              setCartProducts(
                  cartProducts.map(
                      product =>
                      product.id === id                                // if condition
                      ? { ...product, quantity: product.quantity + 1 } // if statement is true
                      : product                                        // if statement is false
                  )
              )
          }
          toast.success(getProductData(id).name + " added to cart", {
            autoClose: 1000
          });
        } else {
          toast.error("Whoa there! You've scooped up all the goodies. There's nothing left to add right now!", {
            autoClose: 2000
          });
          console.log ('Inventory maximum reached!');
        }

        this.setToast(id);
    }

    function removeOneFromCart(id) {
        const quantity = getCartProductQuantity(id);

        if(quantity === 1) {
            deleteFromCart(id);
            //toast.success('Last ' + getProductData(id).name + " removed.!");
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id                                // if condition
                    ? { ...product, quantity: product.quantity - 1 } // if statement is true
                    : product                                        // if statement is false
                )
            )
            //toast.success(getProductData(id).name + " removed.!");
        }
    }

    function deleteFromCart(id) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
                return currentProduct.id !== id;
            })  
        )
    }

    // Dynamically set shipping rate based on subotal.
    //
    // This function uses the fetched shipping rates from Stripe and dynamically sets up the shipping tiers
    // On Stripe dashboard, Shipping rates two fields will need to be setup as metadata
    // Example:
    // tier: 2
    // above_subtotal: 40
    //
    // What this means is if the subtotal is above $40, then use the tier_2 shipping rates
    // At minimum you will need a single tier with above_subtotal set to 0

    function setShippingRate() {
        console.log ('Sub Total: ' + this.getSubTotalCost())
        let subTotalCost = this.getSubTotalCost();

        if (shippingTiers) {
          
            // Function that dynamically determines the shipping rate based on the subtotal
            for (const tier of shippingTiers) {
              if (subTotalCost > tier.above_subtotal) {
                return {
                  rate: tier.id,
                  cost: tier.cost
                };
              }
            }
          
            if (shippingTiers.length > 0) {
              // Default to the lowest tier if no other condition matches
              const lowestTier = shippingTiers[shippingTiers.length - 1];

              console.log ('Lowest Tier: ' +  JSON.stringify(lowestTier));
              return {
                rate: lowestTier.id,
                cost: lowestTier.cost
              };
            }
        }
        
        // Return empty rate if shippingRates is not available
        return { rate: '', cost: 0 };
    };

    function getShippingCost() {
        let shipping = this.setShippingRate();
        console.log ('Shipping Rate ID: ' + shipping.rate);
        console.log ('Shipping Cost: ' + shipping.cost);

        return shipping.cost;
    }

    function getShippingRate() {
        let shipping = this.setShippingRate();
        console.log ('Shipping Rate ID: ' + shipping.rate);
        console.log ('Shipping Cost: ' + shipping.cost);

        return shipping.rate;
    }

    function getSubTotalCost() {
        let subTotalCost = 0;
        /*
        cartProducts.map((cartItem) => {
            const productData = getProductData(cartItem.id);
            subTotalCost += (productData.price * cartItem.quantity);
        });
        */
        cartProducts.forEach((cartItem) => {
          const productData = getProductData(cartItem.id);
          subTotalCost += (productData.prices[0].unit_amount/100 * cartItem.quantity);
        });
        return subTotalCost;
    }


    function getTotalCost() {
        let totalCost = this.getSubTotalCost();
        let shippingCost = this.getShippingCost();

        return shippingCost + totalCost;
    }

    const contextValue = {
        items: cartProducts,
        getCartProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getSubTotalCost,
        getShippingCost,
        setShippingRate,
        getShippingRate,
        getTotalCost,
        setToast,
        formatPrice
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;


// CODE DOWN HERE

// Context (cart, addToCart, removeCart)
// Provider -> gives your React app access to all the things in your context