//import Button from 'react-bootstrap/Button';
import { CartContext } from "../CartContext";
import { useContext } from "react";
import { getProductData } from "../productsStore";

function formatPrice (price, currencyId) {
    switch (currencyId) {
        case 'BRL':
        return (price/100).toFixed(2).replace('.', ',');
        default:
        return (price/100).toFixed(2);
    }
};

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return (
        <>
            <div className="row main align-items-center">
                <div className="col-2">
                    <img className="img-fluid" src={productData.images[0]} style={{width : '100%'}}/>
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className='text-muted'>{productData.category}</div>
                        <div>{productData.name}</div>
                    </div>
                    <div className="row"></div>
                </div>
                <div className="col-2">
                    <a onClick={() => cart.removeOneFromCart(id)} className="add-remove-qty">-</a>
                    {quantity}
                    <a onClick={() => cart.addOneToCart(id, productData.inventory)} className="add-remove-qty">+</a>  
                </div>
                <div className="col-3 text-end">
                    ${formatPrice(quantity * productData.prices[0].unit_amount, "USD")}
                </div>
                {/*<div className="col-1 text-end">
                    <span className="cart-close" onClick={() => cart.deleteFromCart(id)}>✕</span>
                </div>*/}
            </div>
        </>
    )
}

export default CartProduct;