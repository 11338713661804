import { useState, useEffect } from 'react';
// Fetch the below from mongoDB. If the inventory is 0 or lower, then mark it as sold out, instead of Add to Cart.
// When purchases are made, automatically subtract the quantity on mongodb side
// Advanced: when items are returned, add back to the inventory automatically
let productsArray = [];

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

const useAllProducts = (storeName) => {
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const url = storeName ? `${STORE_BACKEND_URL}/users/products/all/${storeName}` : `${STORE_BACKEND_URL}/users/products/all/sunthar`;
        console.log('Fetching from: ' + url);
        const response = await fetch(url);
        productsArray = await response.json();

        const transformedProductsArray = productsArray.map(product => {
          const price = product.prices && product.prices.length > 0 ? product.prices[0].unit_amount / 100 : null;
          return {
            ...product,
            price: price
          };
        });

        console.log('Transformed Product Array: ' + JSON.stringify(transformedProductsArray));
        setProducts(transformedProductsArray);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [storeName]);
  
  return products;
};

const useShipping = () => {
  const [shippingTiers, setShippingTiers] = useState([]);

  useEffect(() => {
    console.log('Fetching from: '+ STORE_BACKEND_URL+'/stripe/shipping');
    const fetchShippingRates = async () => {
      try {
        const response = await fetch(STORE_BACKEND_URL+'/stripe/shipping');
        const shippingResponse = await response.json();

        // Transform the shippingResponse into a trasnformedShippingResponse object with dynamic tiers:
        const trasnformedShippingResponse = shippingResponse.data.reduce((acc, item) => {
          const tier = `tier_${item.metadata.tier}`;
          acc[tier] = { 
              id: item.id, 
              above_subtotal: item.metadata.above_subtotal, 
              cost: (item.fixed_amount.amount/100) 
          };
          return acc;
        }, {});

        console.log ('Transformed Shipping Rates: ' + JSON.stringify(trasnformedShippingResponse));

        const tiers = Object.keys(trasnformedShippingResponse).map(tier => trasnformedShippingResponse[tier]);

        // Sort tiers by above_subtotal in descending order
        tiers.sort((a, b) => b.above_subtotal - a.above_subtotal);

        // Set the sorted shipping tiers
        setShippingTiers(tiers);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchShippingRates();
  }, []);

  return shippingTiers;
};

function getProductData(id) {
    console.log('Get Product Data: ' + JSON.stringify(productsArray));
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, useAllProducts, useShipping, getProductData};